import React, { useState } from 'react';
import { FaAngleRight } from 'react-icons/fa';
import Modal from './Modal';

const Grid = ({ item }) => {
  const [modalData, setModalData] = useState(null);

  const bg = () => {
    if(item.icon !== null) return { background: `url(${item.icon})`};
  };

  return (
    <>
      <div className='news_grid_item' onClick={() => setModalData(item)}>
        <div className='news_grid_item_top' style={bg()}>{item.icon === null && <img src={item.logo} alt='' />}</div>
        <div className='news_grid_item_bottom'>
          <h2 className='epg_title'>{item.title.length > 40 ? item.title.substring(0, 40) + '...' : item.title}</h2>
          <h3 className='epg_date'>{item.date}</h3>
          {item.logo && <img src={item.logo} alt='' className='stream_icon' />}
          <div className='epg_desc'>
            <p>{item.description && item.description.length > 150 ? item.description.substring(0, 150) + '...' : item.description}</p>
            <span><FaAngleRight /></span>
          </div>
        </div>
      </div>
      {modalData && <Modal data={modalData} closeModal={() => setModalData(null)} />}
    </>
  );
};

export default Grid;
