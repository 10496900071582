import React, { useEffect, useCallback } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { MdLaptopMac } from "react-icons/md";
import { IoMdRadio } from "react-icons/io";
import { Services } from "../../services/Services";
import {
  setChannelsParams,
  setCategoryName,
  setCategoryRadioName,
  setRadioCategoryId,
  setRadioStations,
  setLoader,
  setChannels,
} from "../../redux/actions";
import "./Categories.css";
import { MdClose } from "react-icons/md";

const Categories = ({
  status,
  setLoader,
  close,
  location,
  activeCategories,
  setChannelsParams,
  setCategoryName,
  categoryName,
  setCategoryRadioName,
  setRadioCategoryId,
  radioCategoryID,
  setChannels,
  categoryRadioName,
  setRadioStations,
}) => {
  useEffect(() => {
    if (localStorage.jwtToken) {
      Services.getRadios({ category: radioCategoryID }).then((res) => {
        if (res.data.radios) setRadioStations(res.data.radios);
        else setRadioStations(res.data);
      });
    } else {
      Services.getDemoRadios({ category: radioCategoryID }).then((res) => {
        if (res.data.radios) setRadioStations(res.data.radios);
        else setRadioStations(res.data);
      });
    }
  }, [radioCategoryID, setRadioStations]);

  const fetchChannels = useCallback((param) => {
    if (localStorage.jwtToken) {
      Services.getChannels(param)
        .then((res) => {
          setChannels(res.data);
          if (location.pathname === "/channels/epgs") setLoader(false);
        })
        .catch((err) => {
          console.log("ERROR", err);
        });
    } else {
      Services.getDemoChannels(param)
        .then((res) => {
          setChannels(res.data);
          if (location.pathname === "/channels/epgs") setLoader(false);
        })
        .catch((err) => {
          console.log("ERROR", err);
        });
    }
    // eslint-disable-next-line
  }, []);

  const handleClick = (item) => {
    if (location.pathname === "/channels/radios") {
      setCategoryRadioName(item.category_name);
      setRadioCategoryId(item.id);
    } else {
      setCategoryName(item.category_name);
      setLoader(true);
      let param = {
        filter: "",
        category: item.id,
        sort: "",
        favorite: "",
        epgs: 1,
      };
      setChannelsParams(param);
      fetchChannels(param);
    }
    close();
  };

  const handleReset = () => {
    if (location.pathname === "/channels/radios") {
      setCategoryRadioName("All");
      setRadioCategoryId("");
    } else {
      setCategoryName("All");
      setLoader(true);
      let param = { filter: "", category: "", sort: "", favorite: "", epgs: 1 };
      setChannelsParams(param);
      fetchChannels(param);
    }
    close();
  };

  const styles = () => {
    if (
      location.pathname === "/channels/epgs" ||
      location.pathname === "/channels/nowontv"
    ) {
      if (categoryName === "All") return "categories_item active";
      else return "categories_item";
    } else {
      if (location.pathname === "/channels/radios") {
        if (categoryRadioName === "All") return "categories_item active";
        else return "categories_item";
      }
    }
  };

  const itemsStyles = (name) => {
    if (
      location.pathname === "/channels/epgs" ||
      location.pathname === "/channels/nowontv"
    ) {
      if (categoryName === name) return "categories_item active";
      else return "categories_item";
    } else {
      if (location.pathname === "/channels/radios") {
        if (categoryRadioName === name) return "categories_item active";
        else return "categories_item";
      }
    }
  };

  return (
    <div
      className={status ? "categories_wrapper slide" : "categories_wrapper"}
      onClick={close}
    >
      <div className="categories_holder" onClick={(e) => e.stopPropagation()}>
        <div className="close-icon" onClick={close}>
          <MdClose size={42} />
        </div>
        <div className="categories_content">
          <div className={styles()} onClick={handleReset}>
            {window.location.pathname !== "/channels/radios" ? (
              <div className="img_holder">
                <MdLaptopMac />
              </div>
            ) : (
              <div className="img_holder">
                <IoMdRadio />
              </div>
            )}
            <p>All</p>
          </div>
          {activeCategories.map((item) => (
            <div
              key={item.id}
              onClick={() => handleClick(item)}
              className={itemsStyles(item.category_name)}
            >
              <div className="img_holder">
                <img src={item.icon_src} alt="" />
              </div>
              <p>{item.category_name}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, {
  setLoader,
  setChannelsParams,
  setCategoryName,
  setCategoryRadioName,
  setRadioCategoryId,
  setRadioStations,
  setChannels,
})(withRouter(Categories));
