import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { AiOutlineFileExcel } from "react-icons/ai";
import { FaRegStar, FaStar, FaTimes } from "react-icons/fa";
import { Services } from "../../../services/Services";
import {
  // setInternetChannels,
  setFavoritesChannels,
  setPlayerVisible,
  setM3u8ActiveChannel,
  setEpgs,
  setChannels,
  setGuidePosition,
  // setInternetTvPage,
} from "../../../redux/actions";
import Track from "../Track/Track";
import "./InternetTvChannels.css";
import Loader from "../../../components/PaginationLoader/PaginationLoader";

import { Link } from "react-router-dom";
import { paginationOnEndReach } from "../../../helpers/paginationOnEndReach";

const InternetTvChannels = ({
  lang,
  // channels,
  activeChannel,
  favorite,
  // setInternetChannels,
  setEpgs,
  setChannels,
  setM3u8ActiveChannel,
  setFavoritesChannels,
  setPlayerVisible,
  guidePosition,
  setGuidePosition,
  internettvPage,
}) => {
  const [filtered, setFiltered] = useState([]);
  const [lastPage, setLastPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [paramReq, setParamReq] = useState({
    page: 1,
    per_page: 50,
  });
  const [channels, setInternetChannels] = useState([]);
  const [localFavorites, setLocalFavorites] = useState(favorite);

  const fetchAPI = (perPage) => {
    setLoading(true);
    Services.m3u8channelsPagination(paramReq.per_page, paramReq.page).then(
      (res) => {
        setFiltered((p) => p.concat(res.data.data));
        setInternetChannels(channels.concat(res.data.data));
        setLastPage(res.data.meta.last_page);
        setLoading(false);
      }
    );
    Services.getFavorites({ favorite: "" }).then((res) => {
      setFavoritesChannels(favorite.concat(res.data.channels));
    });
  };

  const getMoreChannels = () => {
    if (internettvPage + 1 <= lastPage) {
      setParamReq((prevState) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    }
  };

  // const fetchChannels = () => {
  //   let param = { filter: "", category: "", sort: "", favorite: "", epgs: 1 };
  //   Services.getChannels(param).then((res) => setChannels(res.data));
  // };

  useEffect(() => {
    fetchAPI();
    // eslint-disable-next-line
  }, [paramReq]);

  useEffect(() => {
    if (channels.length > 0) setFiltered(channels);
  }, [channels]);

  const handleSearch = (e) => {
    let result = channels.filter(
      (channel) =>
        channel.name.toLowerCase().indexOf(e.target.value.toLowerCase()) !== -1
    );
    setFiltered(result);
  };

  const addToFavorite = (e, item) => {
    e.stopPropagation();
    let obj = {};
    if (item.stream_path) {
      obj = { m3u_channel_id: item.id };
    } else {
      obj = { channel_id: item.stream_id };
    }
    Services.addFavorite(obj).then(() => {
      setLocalFavorites((p) => [...p, item]);
    });
  };

  const removeToFavorite = (e, item) => {
    e.stopPropagation();
    let obj = {};
    if (item.stream_path) {
      obj = { m3u_channel_id: item.id };
    } else {
      obj = { channel_id: item.stream_id };
    }
    Services.removeFavorite(obj).then(() => {
      const arr = localFavorites.filter((elem) => elem.id !== item.id);
      setLocalFavorites(arr);
    });
  };

  const handleDelete = (e, id) => {
    e.stopPropagation();
    Services.internetChannelsRemove({ channels_ids: [id] }).then(() => {
      const arr = filtered.filter((elem) => elem.id !== id);
      setFiltered(arr);
    });
  };

  const handleClick = (channel) => {
    setEpgs([]);
    setPlayerVisible({ channels, channel, epg: null });
    setM3u8ActiveChannel(channel);
  };

  const activeClass = (item) =>
    activeChannel === item ? "item active" : "item";

  const deactiveGuideTootltips = () => {
    setGuidePosition(0);
    localStorage.setItem("showed_internettv_info", true);
  };

  return (
    <div className="internet_channels light_bg relative">
      <Track />
      {channels.length > 0 ? (
        <div className="flex internet_channels_holder">
          <div className="channels_menu">
            <div className="channels_menu_holder">
              <div className="input_holder">
                <input
                  type="text"
                  placeholder={lang.search.placeholder}
                  onChange={handleSearch}
                />
              </div>
              <div
                className="items"
                style={{
                  overflowY:
                    guidePosition === 8 || guidePosition === 9
                      ? "visible"
                      : "scroll",
                  paddingBottom: 60,
                }}
                onScroll={(e) => paginationOnEndReach(e, getMoreChannels)}
              >
                {filtered.map((item, index) => (
                  <div
                    key={item.id}
                    className={activeClass(item)}
                    onClick={() => handleClick(item)}
                  >
                    <div className="left">
                      {item.stream_icon ? (
                        <img src={item.stream_icon} alt="img" />
                      ) : null}
                      <p>
                        {item.name.length > 20
                          ? item.name.substring(0, 20) + "..."
                          : item.name}
                      </p>
                    </div>
                    <div className="right">
                      {item.internet_channel === 1 && (
                        <FaTimes
                          onClick={(e) => handleDelete(e, item.id)}
                          className="red"
                        />
                      )}
                      {index === 0 && guidePosition === 9 ? (
                        <div className="tooltip eight">
                          <div className="triangle"></div>
                          <p>
                            {lang.guide.internet_channels.channels.remove_info}
                          </p>
                          <div className="buttons">
                            <button
                              className="skip"
                              onClick={(e) => {
                                e.stopPropagation();
                                deactiveGuideTootltips();
                              }}
                            >
                              {lang.guide.internet_channels.skip_btn}
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleDelete(e, item.id);
                                setGuidePosition(10);
                              }}
                            >
                              OK
                            </button>
                          </div>
                        </div>
                      ) : null}
                      {index === 0 && guidePosition === 8 ? (
                        <div className="tooltip seven">
                          <div className="triangle"></div>
                          <p>
                            {
                              lang.guide.internet_channels.channels
                                .favorite_info
                            }
                          </p>
                          <div className="buttons">
                            <button
                              className="skip"
                              onClick={(e) => {
                                e.stopPropagation();
                                deactiveGuideTootltips();
                              }}
                            >
                              {lang.guide.internet_channels.skip_btn}
                            </button>
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                if (favorite.find((el) => el.id === item.id)) {
                                  removeToFavorite(e, item);
                                } else addToFavorite(e, item);
                                setGuidePosition(9);
                              }}
                            >
                              OK
                            </button>
                          </div>
                        </div>
                      ) : null}
                      {localFavorites.find((el) => el.id === item.id) ? (
                        <FaStar
                          className="yellow"
                          onClick={(e) => removeToFavorite(e, item)}
                        />
                      ) : (
                        <FaRegStar onClick={(e) => addToFavorite(e, item)} />
                      )}
                    </div>
                  </div>
                ))}
                {/* <button
                  className="show_more"
                  style={{ position: "relative" }}
                  onClick={showMore}
                >
                  {loading ? <Loader /> : lang.movies.see_more_btn}
                </button> */}
                {loading && (
                  <div style={{ position: "relative", height: 60 }}>
                    <Loader />
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="isEmpty">
            <AiOutlineFileExcel />
            <p> {lang.no_epgs}</p>
          </div>
        </div>
      ) : (
        <div className="no_added_internet_channels">
          <AiOutlineFileExcel />
          <p>{lang.internet_channels.channels.no_channels_msg}</p>
          <Link to="/internetTV/addTVchannels">add a channel</Link>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
  // channels: state.internetChannels,
  favorite: state.favoritesChannels,
  active_channel: state.m3u8ActiveChannel,
  activeChannel: state.m3u8ActiveChannel,
  guidePosition: state.guidePosition,
  internettvPage: state.internettvPage,
});

export default connect(mapStateToProps, {
  // setInternetChannels,
  setChannels,
  setEpgs,
  setM3u8ActiveChannel,
  setFavoritesChannels,
  setPlayerVisible,
  setGuidePosition,
  // setInternetTvPage,
})(InternetTvChannels);
