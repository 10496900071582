import React from 'react'
import { connect } from 'react-redux'
import SettingsMenu from '../SettingsMenu/SettingsMenu'
import { web } from './web';

const Account = ({ lang }) => {
  const signout = () => {
    localStorage.clear()
    // localStorage.removeItem('jwtToken')
    // localStorage.removeItem('username')
    // localStorage.removeItem('package')
    // localStorage.removeItem('cookie')
    // localStorage.removeItem('expire_date')
    // localStorage.removeItem('password')
    window.location.href = '/'
  }

  return (
    <div className="min_height flex white">
      <SettingsMenu />
      <div className="settings_content summary">
        <h3>{lang.settings.submenu.account}</h3>
        <div className="row_between">
          <p>{lang.settings.account.username}</p>
          <p>{localStorage.username}</p>
        </div>
        <div className="row_between">
          <p>{lang.settings.account.email}</p>
          <p>{localStorage.email}</p>
        </div>
        <div className="row_between">
          <p>{lang.settings.summary.package_label}</p>
          <p>{localStorage.package}</p>
        </div>
        <div className="row_between">
          <p>{lang.settings.account.expire}</p>
          <p>
            {JSON.stringify(localStorage.expire_date) === null
              ? null
              : localStorage.expire_date}
          </p>
        </div>
        <div className="row_between">
          <p>{lang.settings.account.used_devices}</p>
          <p>{localStorage.used_devices}</p>
        </div>
        <div className="row_between">
          <p>{lang.settings.summary.browser_label}</p>
          <p>{web()}</p>
        </div>
        <div className="row_between">
          <p>{lang.settings.summary.language_label}</p>
          <p>{lang.name}</p>
        </div>
        <div className="row_between">
          <p>{lang.settings.account.software_version}</p>
          <p>1.0</p>
        </div>
        <button className='settings_btn' onClick={signout}>
          {lang.settings.account.sign_out_btn}
        </button>
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
})

export default connect(mapStateToProps)(Account)
