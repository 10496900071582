import React, { useState, useEffect } from "react";
import { deviceDetect } from "react-device-detect";
import { connect, useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import { FaEye, FaCheck } from "react-icons/fa";
import {
  AiOutlineReload,
  AiOutlineCaretLeft,
  AiOutlineCaretRight,
} from "react-icons/ai";
import axios from "axios";
import {
  setLockedCategories,
  setPinCode,
  setSelectedLanguage,
  setToc,
} from "../redux/actions";
import Logo from "../images/login_logo.png";
import { utility } from "../helpers/Utility";
import { Services } from "../services/Services";
import "./Login.css";
import ReactCodeInput from "react-verification-code-input";
import { isMobile } from "../helpers/deviceDetect";
import verificationCode from "../images/verificationCode.png";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { demo } from "../reskin/reskin";

const Login = ({ setLockedCategories, setPinCode }) => {
  const dispatch = useDispatch();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  // eslint-disable-next-line
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState({
    phone: "",
    password: "",
    toc: "",
  });
  const [codeSent, setCodeSent] = useState(null);
  const [selectStatus, setSelectStatus] = useState(false);
  const [preview, setPreview] = useState(false);
  const [previewConfirm, setPreviewConfirm] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const [tocPosition, setTocPosition] = useState(0);
  const active_lang = useSelector((state) => state.selectedLanguage);
  const toc = useSelector((state) => state.toc);

  const history = useHistory();

  const [verification, setVerification] = useState(false);
  const [verificationErr, setVerificationErr] = useState(null);

  useEffect(() => {
    Services.allLanguages().then((res) => {
      if (Array.isArray(active_lang)) {
        if (!localStorage.getItem("locale")) {
          localStorage.setItem("locale", res.data.languages[0].id);
          dispatch(setSelectedLanguage(res.data.languages[0]));
        } else {
          const id = localStorage.getItem("locale");
          const find = res.data.languages.find(
            (elem) => elem.id === parseInt(id)
          );
          dispatch(setSelectedLanguage(find));
        }
      }
    });
    Services.getToc().then((res) => {
      dispatch(setToc(res.data.images));
    });
    // eslint-disable-next-line
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    axios
      .post(`${utility.baseUrl}promo-register`, {
        name: `+${phone}`,
        phone: `+${phone}`,
        password: password,
        password_confirmation: confirmPassword,
        privacy_policy: agreed,
      })
      .then((res) => {
        setError({
          phone: "",
          password: "",
          toc: "",
        });
        setVerification(true);
      })
      .catch((err) => {
        if (err.response.data.errors) {
          if (err.response.data.errors.phone) {
            setError((p) => ({
              ...p,
              phone: err.response.data.errors.phone[0],
            }));
          } else {
            setError((p) => ({ ...p, phone: "" }));
          }
          if (err.response.data.errors.password) {
            setError((p) => ({
              ...p,
              password: err.response.data.errors.password[0],
            }));
          } else {
            setError((p) => ({ ...p, password: "" }));
          }
          if (err.response.data.errors.privacy_policy) {
            setError((p) => ({
              ...p,
              toc: err.response.data.errors.privacy_policy[0],
            }));
          } else {
            setError((p) => ({ ...p, toc: "" }));
          }
        }
      });
  };

  const login = () => {
    axios
      .post(`${utility.baseUrl}login`, {
        username: `+${phone}`,
        password,
        mac: deviceDetect().userAgent,
      })
      .then((res) => {
        setError({
          phone: "",
          password: "",
          toc: "",
        });
        let customer = res.data.customer;
        localStorage.setItem("jwtToken", res.data.access_token);
        localStorage.setItem("stream_token", res.data.stream_token);
        localStorage.setItem("username", customer.username);
        localStorage.setItem("email", customer.email);
        localStorage.setItem("expire_date", customer.expire_date);
        localStorage.setItem("locale", active_lang.id);
        localStorage.setItem("pincode", customer.pincode);
        localStorage.setItem("all_locked", false);
        localStorage.setItem(
          "used_devices",
          res.data.customer.max_connections_kozmetika
        );
        localStorage.setItem("show_info_box", true);
        localStorage.setItem("show_guide_banner", false);
        localStorage.setItem("showed_general_banner", false);
        localStorage.setItem("showed_internettv_info", false);
        localStorage.setItem("package", customer.package_name);
        setPinCode(customer.pincode);
        setLockedCategories(customer.locked_categories);

        getIp();
      });
  };

  let verification_code = null;

  const handleCode = (resetForm) => {
    // console.log(verification_code);
    axios
      .post(`${utility.baseUrl}verify`, {
        verification_code: verification_code,
      })
      .then(() => {
        setVerification(false);
        setVerificationErr(null);
        login();
      })
      .catch((err) => {
        setVerificationErr("Pogrešan verifikacioni kod");
      });
  };
  const handleResend = () => {
    axios
      .post(`${utility.baseUrl}resend/verification-code`, {
        username: phone,
      })
      .then(() => {
        setCodeSent("Kod poslat");
      })
      .catch((err) => {});
  };

  const getIp = () => {
    axios.get("https://api.ipify.org/?format=json").then((res) => {
      localStorage.setItem("ip", res.data.ip);
      window.location.href = "/";
    });
  };

  const closeSelect = () => {
    if (selectStatus) setSelectStatus(false);
  };

  console.log(error);

  return (
    <>
      {verification ? (
        <div
          className="verificationCode"
          onClick={() => setVerificationErr(false)}
        >
          <div className="bg" onClick={() => setVerification(false)} />
          <div className="vCodeWrapper">
            <img src={verificationCode} alt="Code" />
            <h1>
              {active_lang.login &&
                active_lang.login.verification_code_popup.title}
            </h1>
            <p>
              {active_lang.login &&
                active_lang.login.verification_code_popup.description}
            </p>
            <ReactCodeInput
              type="number"
              fields={4}
              placeholder={["*", "*", "*", "*"]}
              claseName="unos-kod"
              onChange={(e) => (verification_code = e)}
            />
            {verificationErr && (
              <p className="verification-err">{verificationErr}</p>
            )}
            {codeSent && <p className="code-sent">{codeSent}</p>}
            <button onClick={handleCode}>
              {active_lang.login &&
                active_lang.login.verification_code_popup.confirm_btn}
            </button>
            <div className="sendAgain">
              <p>
                {active_lang.login &&
                  active_lang.login.verification_code_popup.code_not_sent}
              </p>
              <span onClick={handleResend}>
                {active_lang.login &&
                  active_lang.login.verification_code_popup.send_code_btn}
              </span>
            </div>
          </div>
        </div>
      ) : null}
      {active_lang.login ? (
        <>
          <div className="login_wrapper" onClick={closeSelect}>
            <div
              className={"login_content register" + (!demo ? " not-demo" : "")}
            >
              <img src={Logo} alt="logo" />

              <form onSubmit={onSubmit}>
                {/* <label>
                  {active_lang.login &&
                    active_lang.login.register_screen.phone_label}
                </label>
                <input type="tel" onChange={(e) => setPhone(e.target.value)} /> */}
                <div className="phoneInput">
                  <label>
                    {active_lang.login &&
                      active_lang.login.register_screen.phone_label}
                  </label>
                  <PhoneInput
                    country={"rs"}
                    value={phone}
                    onChange={(val) => setPhone(val)}
                    countryCodeEditable={false}
                  />
                </div>
                {error.phone && <p className="error-message">{error.phone}</p>}

                <label>
                  {active_lang.login &&
                    active_lang.login.register_screen.password_label}
                </label>
                <div className="input_holder">
                  <input
                    type={preview ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                  />
                  <FaEye onClick={() => setPreview(!preview)} />
                </div>
                {error.password && (
                  <p className="error-message">{error.password}</p>
                )}
                <label>
                  {active_lang.login &&
                    active_lang.login.register_screen.repeat_password_label}
                </label>
                <div className="input_holder">
                  <input
                    type={previewConfirm ? "text" : "password"}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                  />
                  <FaEye onClick={() => setPreviewConfirm((p) => !p)} />
                </div>
                <div className="privacyPolicy">
                  <div
                    className={"checkbox" + (agreed ? " active" : "")}
                    onClick={() => setAgreed((p) => !p)}
                  >
                    <FaCheck />
                  </div>
                  <p>
                    {active_lang.login &&
                      active_lang.login.register_screen.i_agree_label}{" "}
                    <span onClick={() => setTocPosition(1)}>
                      {active_lang.login &&
                        active_lang.login.register_screen
                          .privacy_policy_btn}{" "}
                    </span>
                  </p>
                </div>
                {error.toc && <p className="error-message">{error.toc}</p>}

                <input
                  type="submit"
                  value={active_lang.login && active_lang.login.sign_up}
                />
              </form>
              <div className="dont_acc">
                {active_lang.login &&
                  active_lang.login.register_screen.have_account_label}
                <Link to="login">
                  {active_lang.login && active_lang.login.btn}
                </Link>
              </div>
              {demo && (
                <button
                  className="demoBtn"
                  onClick={() => {
                    localStorage.setItem("locale", active_lang.id);
                    history.push(isMobile ? "/tv" : "/channels/epgs");
                  }}
                >
                  {active_lang.login && active_lang.login.try_free_version}
                </button>
              )}
            </div>
          </div>
          {tocPosition > 0 && (
            <div className="toc">
              <div className="bg" onClick={() => setTocPosition(0)} />
              <div className="termsWrapper">
                <img
                  src={toc.find((elem) => elem.position === tocPosition).url}
                  alt="Terms and conditions"
                />
                <div className="tocController">
                  <div className="btn" onClick={() => setTocPosition(0)}>
                    <AiOutlineReload />
                    <p>{active_lang.news && active_lang.news.back_btn}</p>
                  </div>
                  <div className="middleBtns">
                    <div
                      className="btn"
                      onClick={() =>
                        tocPosition !== 1 && setTocPosition((p) => (p = p - 1))
                      }
                    >
                      <AiOutlineCaretLeft />
                      <p>{active_lang.news && active_lang.news.previous_btn}</p>
                    </div>
                    <div
                      className="btn"
                      onClick={() =>
                        tocPosition !== toc.length &&
                        setTocPosition((p) => (p = p + 1))
                      }
                    >
                      <p>{active_lang.news && active_lang.news.next_btn}</p>
                      <AiOutlineCaretRight />
                    </div>
                  </div>
                  <div style={{ width: 100 }}></div>
                </div>
              </div>
            </div>
          )}
        </>
      ) : null}
    </>
  );
};

export default connect(null, {
  setLockedCategories,
  setPinCode,
  setSelectedLanguage,
})(Login);
