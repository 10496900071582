import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import "./Track.css";
import { setGuidePosition } from "../../../redux/actions";

const Track = ({ lang, guidePosition, setGuidePosition }) => {
  const langauge = lang.internet_channels.submenu;
  const isActive = (path) =>
    window.location.pathname === path ? "active_nav" : "";

  const deactiveGuideTootltips = () => {
    setGuidePosition(0);
    localStorage.setItem("showed_internettv_info", true);
  };

  return (
    <div className="internetTVTrack_wrapper">
      {guidePosition > 1 && guidePosition < 15 ? (
        <div className="overlay" />
      ) : null}
      <Link to="/internetTV/m3u8" className={isActive("/internetTV/m3u8")}>
        {langauge.channels}
        {guidePosition === 7 ? (
          <React.Fragment>
            <div className="tooltip second">
              <div className="triangle"></div>
              <p>{lang.guide.internet_channels.channels.screen_info}</p>
              <div className="buttons">
                <button
                  className="skip"
                  onClick={() => deactiveGuideTootltips()}
                >
                  {lang.guide.internet_channels.skip_btn}
                </button>
                <button onClick={() => setGuidePosition(8)}>
                  {lang.channels.now_on_tv.filter.next}
                </button>
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </Link>

      <Link
        to="/internetTV/addTVchannels"
        className={isActive("/internetTV/addTVchannels")}
      >
        {langauge.add_channels}
        {guidePosition === 2 ? (
          <React.Fragment>
            {/* <div className="overlay"/> */}
            <div className="tooltip second">
              <div className="triangle"></div>
              <p>{lang.guide.internet_channels.add_channels.screen_info}</p>
              <div className="buttons">
                <button
                  className="skip"
                  onClick={() => deactiveGuideTootltips()}
                >
                  {lang.guide.internet_channels.skip_btn}
                </button>
                <button onClick={() => setGuidePosition(3)}>
                  {lang.channels.now_on_tv.filter.next}
                </button>
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </Link>
      <Link
        to="/internetTV/addM3U8list"
        className={isActive("/internetTV/addM3U8list")}
      >
        {langauge.add_m3u8_list}
        {guidePosition === 10 ? (
          <React.Fragment>
            {/* <div className="overlay"/> */}
            <div className="tooltip second">
              <div className="triangle"></div>
              <p>{lang.guide.internet_channels.add_m3u8_list.screen_info}</p>
              <div className="buttons">
                <button
                  className="skip"
                  onClick={() => deactiveGuideTootltips()}
                >
                  {lang.guide.internet_channels.skip_btn}
                </button>
                <button onClick={() => setGuidePosition(11)}>
                  {lang.channels.now_on_tv.filter.next}
                </button>
              </div>
            </div>
          </React.Fragment>
        ) : null}
      </Link>
      <Link
        to="/internetTV/m3u8lists"
        className={isActive("/internetTV/m3u8lists")}
      >
        {langauge.m3u8_lists}
        {guidePosition === 14 ? (
          <div className="tooltip second">
            <div className="triangle"></div>
            <p>{lang.guide.internet_channels.m3u8_lists.screen_info}</p>
            <div className="buttons">
              <button className="skip" onClick={() => deactiveGuideTootltips()}>
                {lang.guide.internet_channels.skip_btn}
              </button>
              <button onClick={() => setGuidePosition(15)}>
                {lang.channels.now_on_tv.filter.next}
              </button>
            </div>
          </div>
        ) : null}
      </Link>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
  guidePosition: state.guidePosition,
});

export default connect(mapStateToProps, { setGuidePosition })(Track);
