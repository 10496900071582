//Export variables
export const SET_LOADER = "SET_LOADER";
export const SET_RADIOS_STATIONS = "SET_RADIOS_STATIONS";
export const SET_CHANNELS = "SET_CHANNELS";
export const SET_CATEGORIES = "SET_CATEGORIES";
export const SET_RADIO_CATEGORIES = "SET_RADIO_CATEGORIES";
export const SET_EPGS = "SET_EPGS";
export const SET_TODAY_EPGS = "SET_TODAY_EPGS";
export const SET_PREV_EPGS = "SET_PREV_EPGS";
export const SET_NEXT_EPGS = "SET_NEXT_EPGS";
export const SET_FAVORITES_CHANNELS = "SET_FAVORITES_CHANNELS";
export const SET_FAVORITES_RADIOS = "SET_FAVORITES_RADIOS";
export const SET_ACTIVE_RADIO = "SET_ACTIVE_RADIO";
export const SET_SEARCH_VALUE = "SET_SEARCH_VALUE";
export const SET_ALL_LANGUAGES = "SET_ALL_LANGUAGES";
export const SET_SELECTED_LANGUAGE = "SET_SELECTED_LANGUAGE";
export const SET_SEARCHED_EPGS = "SET_SEARCHED_EPGS";
export const SET_CHANNELS_PARAMS = "SET_CHANNELS_PARAMS";
export const SET_INTERNET_CHANNELS = "SET_INTERNET_CHANNELS";
export const SET_M3U8_LISTS = "SET_M3U8_LISTS";
export const SET_CATEGORY_NAME = "SET_CATEGORY_NAME";
export const SET_CATEGORY_RADIO_NAME = "SET_CATEGORY_RADIO_NAME";
export const SET_SORT_EPGS = "SET_SORT_EPGS";
export const SET_PREV_3_HOURS = "SET_PREV_3_HOURS";
export const SET_NEXT_3_HOURS = "SET_NEXT_3_HOURS";
export const SET_ACTIVE_STREAM = "SET_ACTIVE_STREAM";
export const SET_IS_LOCKED = "SET_IS_LOCKED";
export const SET_NET_CHANNELS = "SET_NET_CHANNELS";
export const SET_CATCH_UP_STREAM = "SET_CATCH_UP_STREAM";
export const SET_LEFT_SLIDER_DATA = "SET_LEFT_SLIDER_DATA";
export const SET_LEFT_SLIDER_ACTIVE_CHANNEL = "SET_LEFT_SLIDER_ACTIVE_CHANNEL";
export const SET_BOTTOM_SLIDER_ACTIVE_EPG = "SET_BOTTOM_SLIDER_ACTIVE_EPG";
export const SET_BOTTOM_SLIDER_DATA = "SET_BOTTOM_SLIDER_DATA";
export const SET_BOTTOM_SLIDER_ACTIVE_INDEX = "SET_BOTTOM_SLIDER_ACTIVE_INDEX";
export const SET_SELECTED_CHANNELS = "SET_SELECTED_CHANNELS";
export const SET_TV_CHANNELS_ACTIVE_CHANNEL = "SET_TV_CHANNELS_ACTIVE_CHANNEL";
export const SET_INTERNET_TV_ACTIVE_CHANNEL = "SET_INTERNET_TV_ACTIVE_CHANNEL";
export const SET_M3U8_ACTIVE_CHANNEL = "SET_M3U8_ACTIVE_CHANNEL";
export const SET_CAROUSEL_DATA = "SET_CAROUSEL_DATA";
export const SET_RADIO_SLIDER_DATA = "SET_RADIO_SLIDER_DATA";
export const SET_RADIO_CATEGORY_ID = "SET_RADIO_CATEGORY_ID";
export const SET_SOMETHING = "SET_SOMETHING";
export const LOCKED_CATEGORIES = "LOCKED_CATEGORIES";
export const PIN_CODE = "PIN_CODE";
export const SET_INFO_POPUP = "SET_INFO_POPUP";
export const SET_PIN_CODE_MODAL = "SET_PIN_CODE_MODAL";
export const SET_LEFT_SLIDER_PIN = "SET_LEFT_SLIDER_PIN";
export const SET_DOBULE_CLICK_MENU = "SET_DOBULE_CLICK_MENU";
export const SET_PLAYER_EXIT = "SET_PLAYER_EXIT";
export const SET_COUNTRIES_FILTERS = "SET_COUNTRIES_FILTERS";
export const SET_GENRES_FILTERS = "SET_GENRES_FILTERS";
export const DROPDOWNS_VISIBLE = "DROPDOWNS_VISIBLE";
export const INFO_MODAL = "INFO_MODAL";
export const PLAYER_VISIBLE = "PLAYER_VISIBLE";
export const MENU_SELECTED_CHANNEL = "MENU_SELECTED_CHANNEL";
export const ACTIVE_CHANNEL = "ACTIVE_CHANNEL";
export const NEWS_FEED = "NEWS_FEED";
export const SET_NEWS_ITEM = "SET_NEWS_ITEM";
export const CHANGE_INFO_SHOW = "CHANGE_INFO_SHOW";
// export const CHANGE_GUIDE_POSITION = 'CHANGE_GUIDE_POSITION'
export const SET_GUIDE_POSITION = "SET_GUIDE_POSITION";
export const SET_MODAL_VISIBLE = "SET_MODAL_VISIBLE";
export const SET_LANG_MODAL_VISIBLE = "SET_LANG_MODAL_VISIBLE";
export const SET_ADS = "SET_ADS";
export const SET_TOC = "SET_TOC";
export const SET_INTERNETTV_PAGE = "SET_INTERNETTV_PAGE";

//Export functions
export function setPinCodeModal(payload) {
  const action = {
    type: SET_PIN_CODE_MODAL,
    payload,
  };

  return action;
}

export function setRadioStations(stations) {
  const action = {
    type: SET_RADIOS_STATIONS,
    stations,
  };

  return action;
}

export function setChannels(channels) {
  const action = {
    type: SET_CHANNELS,
    channels,
  };

  return action;
}

export function setCategories(category) {
  const action = {
    type: SET_CATEGORIES,
    category,
  };

  return action;
}

export function setRadioCategories(category) {
  const action = {
    type: SET_RADIO_CATEGORIES,
    category,
  };

  return action;
}

export function setEpgs(epg) {
  const action = {
    type: SET_EPGS,
    epg,
  };

  return action;
}

export function setTodayEpgs(epg) {
  const action = {
    type: SET_TODAY_EPGS,
    epg,
  };

  return action;
}

export function setPrevEpgs(epg) {
  const action = {
    type: SET_PREV_EPGS,
    epg,
  };

  return action;
}

export function setNextEpgs(epg) {
  const action = {
    type: SET_NEXT_EPGS,
    epg,
  };

  return action;
}

export function setFavoritesChannels(favorite) {
  const action = {
    type: SET_FAVORITES_CHANNELS,
    favorite,
  };

  return action;
}

export function setFavoritesRadios(favorite) {
  const action = {
    type: SET_FAVORITES_RADIOS,
    favorite,
  };

  return action;
}

export function setActiveRadio(item) {
  const action = {
    type: SET_ACTIVE_RADIO,
    item,
  };

  return action;
}

export function setSearchValue(value) {
  const action = {
    type: SET_SEARCH_VALUE,
    value,
  };

  return action;
}

export function setRadioCategoryId(id) {
  const action = {
    type: SET_RADIO_CATEGORY_ID,
    id,
  };

  return action;
}

export function setAllLanguages(languages) {
  const action = {
    type: SET_ALL_LANGUAGES,
    languages,
  };

  return action;
}

export function setSelectedLanguage(lang) {
  const action = {
    type: SET_SELECTED_LANGUAGE,
    lang,
  };

  return action;
}

export function setSearchedEpgs(term) {
  const action = {
    type: SET_SEARCHED_EPGS,
    term,
  };

  return action;
}

export function setChannelsParams(params) {
  const action = {
    type: SET_CHANNELS_PARAMS,
    params,
  };

  return action;
}

export function setInternetChannels(channel) {
  const action = {
    type: SET_INTERNET_CHANNELS,
    channel,
  };

  return action;
}

export function setM3u8Lists(list) {
  const action = {
    type: SET_M3U8_LISTS,
    list,
  };

  return action;
}

export function setCategoryName(name) {
  const action = {
    type: SET_CATEGORY_NAME,
    name,
  };

  return action;
}

export function setCategoryRadioName(name) {
  const action = {
    type: SET_CATEGORY_RADIO_NAME,
    name,
  };

  return action;
}

export function setSortEpgs(epg) {
  const action = {
    type: SET_SORT_EPGS,
    epg,
  };

  return action;
}

export function setPrev3Hours(epg) {
  const action = {
    type: SET_PREV_3_HOURS,
    epg,
  };

  return action;
}

export function setNext3Hours(epg) {
  const action = {
    type: SET_NEXT_3_HOURS,
    epg,
  };

  return action;
}

export function setActiveStream(stream) {
  const action = {
    type: SET_ACTIVE_STREAM,
    stream,
  };

  return action;
}

export function setIsLocked(lock) {
  const action = {
    type: SET_IS_LOCKED,
    lock,
  };

  return action;
}

export function setNetChannels(channel) {
  const action = {
    type: SET_NET_CHANNELS,
    channel,
  };

  return action;
}

export function setCatchUpStream(stream) {
  const action = {
    type: SET_CATCH_UP_STREAM,
    stream,
  };

  return action;
}

export function setLeftSliderData(data) {
  const action = {
    type: SET_LEFT_SLIDER_DATA,
    data,
  };

  return action;
}

export function setLeftSliderActiveChannel(data) {
  const action = {
    type: SET_LEFT_SLIDER_ACTIVE_CHANNEL,
    data,
  };

  return action;
}

export function setBottomSliderData(data) {
  const action = {
    type: SET_BOTTOM_SLIDER_DATA,
    data,
  };

  return action;
}

export function setBottomSliderActiveEpg(data) {
  const action = {
    type: SET_BOTTOM_SLIDER_ACTIVE_EPG,
    data,
  };

  return action;
}

export function setBottomSliderActiveIndex(data) {
  const action = {
    type: SET_BOTTOM_SLIDER_ACTIVE_INDEX,
    data,
  };

  return action;
}

export function setSelectedChannel(channel) {
  const action = {
    type: SET_SELECTED_CHANNELS,
    channel,
  };

  return action;
}

export function setTVchannelActiveChannel(channel) {
  const action = {
    type: SET_TV_CHANNELS_ACTIVE_CHANNEL,
    channel,
  };

  return action;
}

export function setInternetTVactiveChannel(channel) {
  const action = {
    type: SET_INTERNET_TV_ACTIVE_CHANNEL,
    channel,
  };

  return action;
}

export function setM3u8ActiveChannel(channel) {
  const action = {
    type: SET_M3U8_ACTIVE_CHANNEL,
    channel,
  };

  return action;
}

export function setCarouselData(data) {
  const action = {
    type: SET_CAROUSEL_DATA,
    data,
  };

  return action;
}

export function setRadioSliderData(payload) {
  const action = {
    type: SET_RADIO_SLIDER_DATA,
    payload,
  };

  return action;
}

export function setSomething(payload) {
  const action = {
    type: SET_SOMETHING,
    payload,
  };

  return action;
}

export function setLockedCategories(payload) {
  const action = {
    type: LOCKED_CATEGORIES,
    payload,
  };

  return action;
}

export function setPinCode(payload) {
  const action = {
    type: PIN_CODE,
    payload,
  };

  return action;
}

export function setInfoPopup(payload) {
  const action = {
    type: SET_INFO_POPUP,
    payload,
  };

  return action;
}

export function setLeftSliderPin(payload) {
  const action = {
    type: SET_LEFT_SLIDER_PIN,
    payload,
  };

  return action;
}

export function setDobuleClickMenu(payload) {
  const action = {
    type: SET_DOBULE_CLICK_MENU,
    payload,
  };

  return action;
}

export function setPlayerExit(payload) {
  const action = {
    type: SET_PLAYER_EXIT,
    payload,
  };

  return action;
}

export function setCountries(payload) {
  const action = {
    type: SET_COUNTRIES_FILTERS,
    payload,
  };

  return action;
}

export function setGenres(payload) {
  const action = {
    type: SET_GENRES_FILTERS,
    payload,
  };

  return action;
}

export function setLoader(payload) {
  const action = {
    type: SET_LOADER,
    payload,
  };

  return action;
}

export function setDropdownsVisible(payload) {
  const action = {
    type: DROPDOWNS_VISIBLE,
    payload,
  };

  return action;
}

export function setInfoModal(payload) {
  const action = {
    type: INFO_MODAL,
    payload,
  };

  return action;
}

export function setPlayerVisible(payload) {
  const action = {
    type: PLAYER_VISIBLE,
    payload,
  };

  return action;
}

export function setMenuSelectedChannel(payload) {
  const action = {
    type: MENU_SELECTED_CHANNEL,
    payload,
  };

  return action;
}

export function setActiveChannel(payload) {
  const action = {
    type: ACTIVE_CHANNEL,
    payload,
  };

  return action;
}

export function setNewsFeed(payload) {
  const action = {
    type: NEWS_FEED,
    payload,
  };

  return action;
}

export function setNewsItem(payload) {
  const action = {
    type: SET_NEWS_ITEM,
    payload,
  };

  return action;
}

export function changeInfoShow(payload) {
  const action = {
    type: CHANGE_INFO_SHOW,
    payload,
  };

  return action;
}

export function setGuidePosition(payload) {
  const action = {
    type: SET_GUIDE_POSITION,
    payload,
  };

  return action;
}

//NO ACCESS MODAL
export function setModalVisible(payload) {
  return {
    type: SET_MODAL_VISIBLE,
    payload,
  };
}

//ADS

export function setAds(payload) {
  return {
    type: SET_ADS,
    payload,
  };
}

//CHOOSE LANGUAGE MODAL

export function setLanguageModalVisible(payload) {
  return {
    type: SET_LANG_MODAL_VISIBLE,
    payload,
  };
}

//TOC

export function setToc(payload) {
  return {
    type: SET_TOC,
    payload,
  };
}

// internet tv pagination

export function setInternetTvPage(payload) {
  return {
    type: SET_INTERNETTV_PAGE,
    payload,
  };
}
