import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { FaHome, FaStar, FaCog, FaSistrix, FaTimes } from "react-icons/fa";
import { RiLoginCircleLine } from "react-icons/ri";
import { IoMdHelp } from "react-icons/io";
import { FiLogIn } from "react-icons/fi";
import { Services } from "../../services/Services";
import {
  setSearchValue,
  setGuidePosition,
  setModalVisible,
} from "../../redux/actions";
import Logo from "../../images/root_logo.png";
import NewsIcon from "../../images/news.png";
import tv_menu_icon from "../../images/tv_menu_icon.png";
import internet_tv_menu_icon from "../../images/internet_tv_menu_icon.png";
import "./Navbar.css";

const Navbar = ({
  lang,
  history,
  setSearchValue,
  infoShow,
  guidePosition,
  setGuidePosition,
}) => {
  const dispatch = useDispatch();
  const [activeSearch, setActiveSearch] = useState(false);
  // eslint-disable-next-line
  const [result, setResult] = useState([]);
  const [showGuide, setShowGuide] = useState(
    localStorage.getItem("show_info_box")
  );
  const [showedInternetTv, setShowedInternetTv] = useState(
    localStorage.getItem("showed_internettv_info")
  );

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let navbar = document.querySelector(".navbar_wrapper");
      if (window.pageYOffset > 150) navbar.classList.add("sticky");
      else navbar.classList.remove("sticky");
    });
    // console.log(lang.channels.now_on_tv.filter.next);
  });

  useEffect(() => {
    if (!infoShow || infoShow === "false") {
      setShowGuide("false");
      setShowedInternetTv("true");
    } else {
      setShowGuide("true");
      setShowedInternetTv("false");
    }
  }, [infoShow]);

  const onChange = (e) => {
    let searchValue = e.target.value;

    Services.searchEpgs({ term: searchValue, limit: 300 }).then((res) => {
      // console.log(res.data);
      setResult(res.data);
      let format = [];
      res.data.map((item) => format.push({ channel: item.channel, epg: item }));

      if (searchValue.length > 2) {
        setSearchValue(format);
        history.push("/search");
      }
    });
  };

  const closeSearch = () => {
    setActiveSearch(false);
    setSearchValue("");
  };

  const isActive = (path) =>
    window.location.pathname === path
      ? "navbar_item_active navbar_item"
      : "navbar_item";

  const activeClass = (path) =>
    window.location.pathname.includes(path)
      ? "navbar_item_active navbar_item"
      : "navbar_item";

  const deactiveGuideTootltips = () => {
    setShowedInternetTv("true");
    localStorage.setItem("showed_internettv_info", true);
  };

  let urlInternetTv = "/internetTV/m3u8";

  if (localStorage.getItem("show_info_box") === "true") {
    if (guidePosition > 0 && guidePosition < 7) {
      urlInternetTv = "/internetTV/addTVchannels";
    }
  }

  return (
    <div className="navbar_wrapper">
      <div className="navbar">
        <div className="container">
          <div className="navbar_holder">
            <Link
              to={localStorage.jwtToken ? "/" : "/tv"}
              className="nav_logo_holder"
            >
              <img src={Logo} alt="logo" className="nav_logo" />
            </Link>
            <div className="navbar_items">
              <Link
                to={localStorage.jwtToken ? "/" : "/tv"}
                className={
                  localStorage.jwtToken ? isActive("/") : isActive("/tv")
                }
              >
                <span>
                  <FaHome />
                </span>
                {lang.menu && lang.menu.home}
              </Link>
              <Link
                to={localStorage.jwtToken ? "/channels/epgs" : "/"}
                className={activeClass("/channels")}
              >
                <span>
                  <img src={tv_menu_icon} alt="" />
                </span>
                {lang.menu && lang.menu.tv_channels}
              </Link>
              {localStorage.jwtToken ? (
                <Link to={urlInternetTv} className={activeClass("/internetTV")}>
                  <span>
                    <img src={internet_tv_menu_icon} alt="" />
                  </span>
                  {lang.menu && lang.menu.internet_tv}
                  {showedInternetTv === "false" &&
                  guidePosition === 1 &&
                  window.location.pathname.includes("/internetTV") ? (
                    <React.Fragment>
                      <div className="overlay" />
                      <div className="tooltip">
                        <div className="triangle" style={{ left: "42%" }}></div>
                        <p>
                          {lang.guide &&
                            lang.guide.internet_channels_guide_info}
                        </p>
                        <div className="buttons">
                          <button
                            className="skip"
                            onClick={() => deactiveGuideTootltips()}
                          >
                            {lang.guide &&
                              lang.guide.internet_channels.skip_btn}
                          </button>
                          <button
                            onClick={(e) => {
                              e.preventDefault();
                              setGuidePosition(2);
                            }}
                          >
                            {lang.channels &&
                              lang.channels.now_on_tv.filter.next}
                          </button>
                        </div>
                      </div>
                    </React.Fragment>
                  ) : null}
                </Link>
              ) : (
                <div
                  className="navbar_item"
                  onClick={() =>
                    dispatch(
                      setModalVisible(lang.menu && lang.menu.internet_tv)
                    )
                  }
                >
                  <span>
                    <img src={internet_tv_menu_icon} alt="" />
                  </span>
                  {lang.menu && lang.menu.internet_tv}
                </div>
              )}

              <Link to="/news" className={isActive("/news")}>
                <span>
                  <img src={NewsIcon} alt="" className="news_icon" />
                </span>
                {lang.menu && lang.menu.news}
              </Link>

              {localStorage.jwtToken ? (
                <Link
                  to="/favorites/channel"
                  className={activeClass("/favorites")}
                >
                  <span>
                    <FaStar />
                  </span>
                  {lang.menu && lang.menu.favorites}
                </Link>
              ) : (
                <div
                  className="navbar_item"
                  onClick={() =>
                    dispatch(setModalVisible(lang.menu && lang.menu.favorites))
                  }
                >
                  <span>
                    <FaStar />
                  </span>
                  {lang.menu && lang.menu.favorites}
                </div>
              )}
              {localStorage.jwtToken ? (
                <Link
                  to="/settings/account"
                  className={activeClass("/settings")}
                >
                  <span>
                    <FaCog />
                  </span>
                  {lang.menu && lang.menu.settings}
                </Link>
              ) : (
                <div
                  className="navbar_item"
                  onClick={() =>
                    dispatch(setModalVisible(lang.menu && lang.menu.settings))
                  }
                >
                  <span>
                    <FaCog />
                  </span>
                  {lang.menu && lang.menu.settings}
                </div>
              )}
              <div className="search_holder hidden">
                <FaSistrix className="search_icon" />
              </div>
            </div>
            {localStorage.jwtToken && (
              <div className="search">
                {activeSearch ? (
                  <div className="search_holder">
                    <FaSistrix className="search_icon" />
                    <input
                      type="text"
                      placeholder={lang.search && lang.search.placeholder}
                      title="Please enter minimum 3 characters"
                      onChange={onChange}
                      autoFocus
                    />
                    <FaTimes onClick={closeSearch} className="fa-times" />
                  </div>
                ) : (
                  <FaSistrix
                    className="search_icon"
                    onClick={() => setActiveSearch(true)}
                  />
                )}
                <div className="help">
                  <IoMdHelp onClick={() => history.push("/settings/help")} />
                  {showGuide === "true" ? (
                    <div className="tooltip">
                      <div className="triangle"></div>
                      <p>{lang.guide && lang.guide.if_you_need_help_info}</p>
                      <button onClick={() => setShowGuide(false)}>OK</button>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
            {!localStorage.jwtToken && (
              <div className="login-register">
                <Link to="/login" className="navbar_item">
                  <span>
                    <RiLoginCircleLine />
                  </span>
                  LOGIN
                </Link>
                <Link className="navbar_item" to="/signup">
                  <span>
                    <FiLogIn />
                  </span>
                  REGISTER
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
  infoShow: state.infoShow,
  guidePosition: state.guidePosition,
});

export default connect(mapStateToProps, { setSearchValue, setGuidePosition })(
  withRouter(Navbar)
);
