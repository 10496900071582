import React, { useState, useEffect, useCallback } from "react";
import FullScreenIcon from "../../../images/player_icons/fullscreen.png";
import VolumeIcon from "../../../images/player_icons/volume.png";
import MuteIcon from "../../../images/player_icons/mute.png";
import { timeInterval, printTime } from "./helper";
// import { AiOutlineBlock } from "react-icons/ai";
import PipIcon from "../../../images/player_icons/pip.png";

import Logo from "../../../images/login_logo.png";
import InternetTV from "../../../images/player_icons/InternetTV.png";
import FTAImg from "../../../images/player_icons/ftaImg.png";
import LocalTvImg from "../../../images/player_icons/localTvImg.png";

var progressInterval;

const Volume = ({
  epg,
  channel,
  epgs,
  player,
  volume,
  setVolume,
  toggleFullScreen,
  enablePip,
  internetTv,
  fta,
  local,
  m3u,
}) => {
  const [time, setTime] = useState("00:00");

  const startProgress = useCallback(() => {
    clearInterval(progressInterval);
    if (epg && !channel.stream_path && epgs.length > 0)
      progressInterval = setInterval(() => {
        setTime(timeInterval(epg, player));
      }, 1000);
  }, [channel, player, epgs, epg]);

  useEffect(() => {
    startProgress();
  }, [startProgress]);

  const settingVolume = (e) => {
    setVolume(parseFloat(e.target.value));
  };

  let emiterShow = <img src={Logo} alt="Logo" />;

  if (internetTv || m3u) {
    emiterShow = (
      <React.Fragment>
        <img className="InternetTv" src={InternetTV} alt="InternetTV" />
        <p>Internet TV kanali</p>
      </React.Fragment>
    );
  } else if (fta) {
    emiterShow = (
      <React.Fragment>
        <img className="InternetTv" src={FTAImg} alt="InternetTV" />
        <p>FTA slobodni kanali</p>
      </React.Fragment>
    );
  } else if (local) {
    emiterShow = (
      <React.Fragment>
        <img className="InternetTv" src={LocalTvImg} alt="InternetTV" />
        <p>Lokalni Internet TV kanali</p>
      </React.Fragment>
    );
  }

  return (
    <div className="video_player_controls_right">
      {!channel.tutorial && (
        <React.Fragment>
          <p className="emiter">Emiter: {emiterShow}</p>
          {epg && (
            <p>
              <span>{time}</span> / <span>{printTime(epg)}</span>
            </p>
          )}
          <div className="volume_holder">
            {volume > 0 ? (
              <img src={VolumeIcon} onClick={() => setVolume(0)} alt="" />
            ) : (
              <img src={MuteIcon} onClick={() => setVolume(1)} alt="" />
            )}
            <div className="range_holder">
              <div className="range_wrap">
                <input
                  type="range"
                  min={0}
                  max={1}
                  step={0.1}
                  value={volume}
                  onChange={settingVolume}
                />
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
      <div className="enable_pip" onClick={enablePip}>
        {/* <AiOutlineBlock /> */}
        <img src={PipIcon} alt="Pin icon" />
      </div>
      <img
        src={FullScreenIcon}
        onClick={toggleFullScreen}
        className="fullscreen_icon"
        alt=""
      />
    </div>
  );
};

export default Volume;
