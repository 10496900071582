import { utility } from "../helpers/Utility";

export const Services = {};

const baseUrl = utility.baseUrl;

Services.getChannels = (data) => {
  localStorage.setItem("path", "channels");
  return utility.axiosInstance().post(`${baseUrl}channels`, data);
};

// demo service
Services.getDemoChannels = (data) => {
  localStorage.setItem("path", "demo/channels");
  return utility.axiosInstance().post(`${baseUrl}demo/channels`, data);
};

Services.getCategories = (data) => {
  localStorage.setItem("path", "categories");
  return utility.axiosInstance().post(`${baseUrl}categories`, data);
};

// demo service
Services.getDemoCategories = (data) => {
  localStorage.setItem("path", "demo/categories");
  return utility.axiosInstance().post(`${baseUrl}demo/categories`, data);
};

Services.getRadioCategories = (data) => {
  localStorage.setItem("path", "radio/categories");
  return utility.axiosInstance().post(`${baseUrl}radio/categories`, data);
};

//demo service
Services.getDemoRadioCategories = (data) => {
  localStorage.setItem("path", "radio/categories");
  return utility.axiosInstance().post(`${baseUrl}demo/radio/categories`, data);
};

Services.getEpgs = (data) => {
  localStorage.setItem("path", "epgs");
  return utility.axiosInstance().post(`${baseUrl}epgs`, data);
};

// demo service
Services.getDemoEpgs = (data) => {
  localStorage.setItem("path", "demo/epgs");
  return utility.axiosInstance().post(`${baseUrl}demo/epgs`, data);
};

Services.getRadios = (data) => {
  localStorage.setItem("path", "radios");
  return utility.axiosInstance().post(`${baseUrl}radios`, data);
};

// demo service
Services.getDemoRadios = (data) => {
  localStorage.setItem("path", "demo/radios");
  return utility.axiosInstance().post(`${baseUrl}demo/radios`, data);
};

Services.allLanguages = (data) => {
  localStorage.setItem("path", "languages");
  return utility.axiosInstance().get(`${baseUrl}languages`, data);
};

Services.getLanguage = (data) => {
  localStorage.setItem("path", "language");
  return utility.axiosInstance().get(`${baseUrl}language`, data);
};

// demo service
Services.getDemoLanguage = (data) => {
  localStorage.setItem("path", "demo/language");
  return utility
    .axiosInstance()
    .get(`${baseUrl}demo/language?language_id=${localStorage.locale}`, data);
};

Services.changeLanguage = (data) => {
  localStorage.setItem("path", "change-language");
  return utility.axiosInstance().post(`${baseUrl}change-language`, data);
};

Services.getFavorites = (data) => {
  localStorage.setItem("path", "favorites");
  return utility.axiosInstance().post(`${baseUrl}favorites`, data);
};

Services.addFavorite = (data) => {
  localStorage.setItem("path", "favorite/add");
  return utility.axiosInstance().post(`${baseUrl}favorite/add`, data);
};

Services.removeFavorite = (data) => {
  localStorage.setItem("path", "favorite/remove");
  return utility.axiosInstance().post(`${baseUrl}favorite/remove`, data);
};

Services.search = (data) => {
  localStorage.setItem("path", "search");
  return utility.axiosInstance().post(`${baseUrl}search`, data);
};

Services.epgsDates = (data) => {
  localStorage.setItem("path", "epg-dates");
  return utility.axiosInstance().post(`${baseUrl}epg-dates`, data);
};

Services.epgsDailys = (data) => {
  localStorage.setItem("path", "daily-epgs");
  return utility.axiosInstance().post(`${baseUrl}daily-epgs`, data);
};

Services.searchEpgs = (data) => {
  localStorage.setItem("path", "epg/search");
  return utility.axiosInstance().post(`${baseUrl}epg/search`, data);
};

Services.m3u8lists = (data) => {
  localStorage.setItem("path", "m3u/lists");
  return utility.axiosInstance().get(`${baseUrl}m3u/lists`, data);
};

Services.getVideoTutorials = (data) => {
  localStorage.setItem("path", "video-tutorials/categories");
  return utility
    .axiosInstance()
    .get(`${baseUrl}video-tutorials/categories`, data);
};

Services.getVideoTutorialsCategory = (data) => {
  localStorage.setItem("path", "video-tutorials");
  return utility.axiosInstance().get(`${baseUrl}video-tutorials`, data);
};

Services.m3u8channels = (data) => {
  localStorage.setItem("path", `m3u/channels?limit=${data}`);
  return utility.axiosInstance().get(`${baseUrl}m3u/channels?limit=${data}`);
};

Services.m3u8channelsPagination = (per_page, page) => {
  localStorage.setItem(
    "path",
    `m3u/channels/p?per_page=${per_page}&page=${page}`
  );
  return utility
    .axiosInstance()
    .get(`${baseUrl}m3u/channels/p?per_page=${per_page}&page=${page}`);
};

Services.m3u8FreeLists = (data) => {
  localStorage.setItem("path", `m3u/free-lists?country_id=${data}`);
  return utility
    .axiosInstance()
    .get(`${baseUrl}m3u/free-lists?country_id=${data}`);
};

Services.m3u8upload = (data) => {
  localStorage.setItem("path", "m3u/upload");
  return utility.axiosInstance().post(`${baseUrl}m3u/upload`, data);
};

Services.m3u8remove = (data) => {
  localStorage.setItem("path", "m3u/delete");
  return utility.axiosInstance().post(`${baseUrl}m3u/delete`, data);
};

Services.internetChannels = (data) => {
  localStorage.setItem("path", "internet-channels");
  return utility.axiosInstance().get(`${baseUrl}internet-channels`, data);
};

Services.searchInternetChannels = (data) => {
  localStorage.setItem("path", "internet-channels/search");
  return utility
    .axiosInstance()
    .post(`${baseUrl}internet-channels/search`, data);
};

Services.internetChannelsAdd = (data) => {
  localStorage.setItem("path", "internet-channels/add");
  return utility.axiosInstance().post(`${baseUrl}internet-channels/add`, data);
};

Services.internetChannelsRemove = (data) => {
  localStorage.setItem("path", "internet-channels/remove");
  return utility
    .axiosInstance()
    .post(`${baseUrl}internet-channels/remove`, data);
};

Services.getCatchUpChannels = (data) => {
  localStorage.setItem("path", "catch-up/channels");
  return utility.axiosInstance().post(baseUrl + "catch-up/channels", data);
};

Services.getBanners = () => {
  localStorage.setItem("path", "banner");
  return utility.axiosInstance().get(baseUrl + "banner");
};

// demo service
Services.getDemoBanners = () => {
  localStorage.setItem("path", "demo/banner");
  return utility.axiosInstance().get(`${baseUrl}demo/banner`);
};

Services.setPincode = (data) => {
  localStorage.setItem("path", "change/pincode");
  return utility.axiosInstance().post(`${baseUrl}change/pincode`, data);
};

Services.lockCategory = (data) => {
  localStorage.setItem("path", "lock/category");
  return utility.axiosInstance().post(`${baseUrl}lock/category`, data);
};

Services.unlockCategory = (data) => {
  localStorage.setItem("path", "unlock/category");
  return utility.axiosInstance().post(`${baseUrl}unlock/category`, data);
};

Services.getUser = () => {
  localStorage.setItem("path", "user");
  return utility.axiosInstance().get(`${baseUrl}user`);
};

Services.getInternetChannelsFilters = () => {
  localStorage.setItem("path", "internet-channels/filters");
  return utility.axiosInstance().get(`${baseUrl}internet-channels/filters`);
};

Services.onTV = (period, sort, category) => {
  localStorage.setItem(
    "path",
    `on-tv?period=${period}&sort=${sort}&per_page=100&category=${category}`
  );
  return utility
    .axiosInstance()
    .get(
      `${baseUrl}on-tv?period=${period}&sort=${sort}&per_page=100&category=${category}`
    );
};

Services.onTVPagination = (period, sort, category, per_page, page) => {
  localStorage.setItem(
    "path",
    `on-tv?period=${period}&sort=${sort}&per_page=${per_page}&page=${page}&category=${category}`
  );
  return utility
    .axiosInstance()
    .get(
      `${baseUrl}on-tv?period=${period}&sort=${sort}&per_page=${per_page}&page=${page}&category=${category}`
    );
};

Services.news_countries = () => {
  localStorage.setItem("path", "news/countries");
  return utility.axiosInstance().get(`${baseUrl}news/countries`);
};

Services.demo_news_countries = () => {
  localStorage.setItem("path", "demo/news/countries");
  return utility.axiosInstance().get(`${baseUrl}demo/news/countries`);
};

Services.news_filter = (country_id) => {
  localStorage.setItem("path", `news/filter?country_id=${country_id}`);
  return utility
    .axiosInstance()
    .get(`${baseUrl}news/filter?country_id=${country_id}`);
};

Services.demo_news_filter = (country_id) => {
  localStorage.setItem("path", `demo/news/filter?country_id=${country_id}`);
  return utility
    .axiosInstance()
    .get(`${baseUrl}demo/news/filter?country_id=${country_id}`);
};

Services.news_feed = (country_id, filter_id) => {
  localStorage.setItem(
    "path",
    `news/feed?country_id=${country_id}&filter_id=${filter_id}`
  );
  return utility
    .axiosInstance()
    .get(`${baseUrl}news/feed?country_id=${country_id}&filter_id=${filter_id}`);
};

Services.demo_news_feed = (country_id, filter_id) => {
  localStorage.setItem(
    "path",
    `demo/news/feed?country_id=${country_id}&filter_id=${filter_id}`
  );
  return utility
    .axiosInstance()
    .get(
      `${baseUrl}demo/news/feed?country_id=${country_id}&filter_id=${filter_id}`
    );
};

Services.getAds = () => {
  return utility.axiosInstance().get(`${baseUrl}guides-new?type=Ads`);
};

Services.getToc = () => {
  return utility.axiosInstance().get(`${baseUrl}guides-new?type=ToC`);
};

Services.getCallCenters = () => {
  return utility.axiosInstance().get(`${baseUrl}info`);
};
