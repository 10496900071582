import React, { Component } from "react";
import { connect } from "react-redux";
import { AiOutlineFileExcel } from "react-icons/ai";
import { utility } from "../../../../helpers/Utility";
import { play_or_prev, isCatchup } from "../../../../helpers/icons";
import {
  setInfoModal,
  setPlayerVisible,
  setEpgs,
} from "../../../../redux/actions";
// import PlayImg from "../../../../images/play.png";
// import Rewind from "../../../../images/rewind.png";
// import CatchupImg from "../../../../images/player_icons/restart1.png";
import MarkerImg from "../../../../images/marker.png";
import { FaRegPlayCircle } from "react-icons/fa";
import { MdRefresh } from "react-icons/md";
import { FaFastBackward } from "react-icons/fa";
import DefaultItemImg from "../../../../images/mini_logo.png";
import "./Grid.css";

const moment = require("moment-timezone");
const timezone = moment.tz.guess();
var offset = 0;

class Grid extends Component {
  componentDidUpdate() {
    this.markerSetup();
  }

  componentDidMount() {
    this.markerSetup();
  }

  markerSetup = () => {
    const { channel, epgs, timeText } = this.props;
    var top = document.querySelector(".marker-top");
    var bottom = document.querySelector(".marker-bottom");
    var div = document.querySelector(".channels_stream");

    if (timeText !== " Now") {
      top.style.display = "none";
      bottom.style.display = "none";
      div.scrollTop = 0;
      div.removeEventListener("scroll", this.marker);
    }

    if (
      epgs &&
      epgs.length &&
      channel.current_epg &&
      channel.current_epg !== null
    ) {
      let currentEpg = channel.current_epg.id;
      let currentIndex = epgs.map((item) => item.id).indexOf(currentEpg);
      let currentDiv = document.getElementById(`stream_${currentIndex}`);
      if (currentDiv !== null) {
        let currentDivOffset = currentDiv.offsetTop;
        div.scrollTop = currentDivOffset;
        offset = currentDivOffset;
        div.addEventListener("scroll", this.marker);
      }
    } else {
      top.style.display = "none";
      bottom.style.display = "none";
      div.scrollTop = 0;
      div.removeEventListener("scroll", this.marker);
    }
  };

  marker = () => {
    let div = document.querySelector(".channels_stream");
    let element = document.querySelector(".channels_stream_item");
    let top = document.querySelector(".marker-top");
    let bottom = document.querySelector(".marker-bottom");
    if (element) {
      let elHeight = element.clientHeight;

      if (offset > div.scrollTop + elHeight) {
        top.style.display = "none";
        bottom.style.display = "block";
      } else if (offset < div.scrollTop - elHeight) {
        top.style.display = "block";
        bottom.style.display = "none";
      } else {
        top.style.display = "none";
        bottom.style.display = "none";
      }
    }
  };

  goToMarker = (e) => {
    e.stopPropagation();
    const { channel, epgs } = this.props;
    let div = document.querySelector(".channels_stream");
    if (channel.current_epg && channel.current_epg !== null) {
      let currentEpg = channel.current_epg.id;
      let currentIndex = epgs.map((item) => item.id).indexOf(currentEpg);
      let currentDiv = document.getElementById(`stream_${currentIndex}`);
      let currentDivOffset = currentDiv.offsetTop;
      div.scrollTop = currentDivOffset;
      offset = currentDivOffset;
    }
  };

  renderIcons = (epg) => {
    const { channel } = this.props;
    let icons = play_or_prev(epg, channel);
    let catchup = isCatchup(channel);

    if (icons === 1) {
      return (
        <>
          <img src={MarkerImg} className="marker-center" alt="" />
          <div className="icons">
            {catchup && (
              // <img
              //   src={CatchupImg}
              //   className="control_icon"
              //   onClick={(e) => this.handleClick(e, epg)}
              //   alt=""
              // />
              <div
                className="control_icon catch-up"
                onClick={(e) => this.handleClick(e, epg, true)}
              >
                <MdRefresh />
              </div>
            )}
            {/* <img
              src={PlayImg}
              className="control_icon"
              onClick={(e) => this.handleClick(e, epg)}
              alt=""
            /> */}
            <div
              className="control_icon"
              onClick={(e) => this.handleClick(e, epg, false)}
            >
              <FaRegPlayCircle />
            </div>
          </div>
        </>
      );
    } else if (icons === 0) {
      return (
        <div className="icons">
          {/* <img
            src={Rewind}
            className="control_icon"
            onClick={(e) => this.handleClick(e, epg)}
            alt=""
          /> */}
          <div
            className="control_icon backward"
            onClick={(e) => this.handleClick(e, epg, false)}
          >
            <FaFastBackward />
          </div>
        </div>
      );
    }
  };

  handleClick = (e, epg, fromStart) => {
    const { channels, channel, epgs, setPlayerVisible, setEpgs } = this.props;
    e.stopPropagation();
    setEpgs(epgs);
    setPlayerVisible({ channels, channel, epg, fromStart });
  };

  styles = (item) => {
    if (item.epg_img) {
      return {
        background: `url(${item.epg_img})`,
        backgroundSize: "cover",
      };
    } else {
      return {
        background: `url(${DefaultItemImg})`,
        backgroundSize: "contain",
      };
    }
  };

  sort = () => {
    const { sortValue, lang } = this.props;
    if (sortValue === lang.sort.placeholder)
      return (a, b) => a.start.localeCompare(b.start);
    else if (sortValue === lang.sort.asc.name)
      return (a, b) => a.title.localeCompare(b.title);
    else return (a, b) => b.title.localeCompare(a.title);
  };

  openInfoModal = (epg) =>
    this.props.setInfoModal({ channel: this.props.channel, epg });

  render() {
    const { epgs } = this.props;
    return (
      <>
        <img
          onClick={this.goToMarker}
          src={MarkerImg}
          className="marker-top"
          alt=""
        />
        <img
          onClick={this.goToMarker}
          src={MarkerImg}
          className="marker-bottom"
          alt=""
        />
        <div className="fixed_shadow" />
        <div className="channels_stream">
          {epgs && epgs.length > 0 ? (
            epgs.sort(this.sort()).map((item, index) => (
              <div
                key={index}
                id={`stream_${index}`}
                className="channels_stream_item"
                style={this.styles(item)}
                onClick={() => this.openInfoModal(item)}
              >
                {this.renderIcons(item)}
                <div className="proggres_bar">
                  <div
                    className="buffer"
                    style={{ width: utility.progressBarWidth(item) }}
                  />
                </div>
                <div className="channels_stream_item_content">
                  <h5>
                    {moment
                      .tz(item.start, utility.timezone)
                      .tz(timezone)
                      .format("HH:mm")}
                  </h5>
                  <h2>{item.title}</h2>
                </div>
              </div>
            ))
          ) : (
            <div className="nochannel_wrap">
              <AiOutlineFileExcel />
              {this.props.lang.no_epgs}
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  channels: state.channels,
  channel: state.tvChannelsActiveChannel,
  lang: state.selectedLanguage,
});

export default connect(mapStateToProps, {
  setInfoModal,
  setPlayerVisible,
  setEpgs,
})(Grid);
