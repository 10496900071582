import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Services } from "../../../services/Services";
import {
  setM3u8Lists,
  setGuidePosition,
  setCountries,
} from "../../../redux/actions";
import Track from "../Track/Track";
import SuccessPopup from "./SuccessPopup";
import ErrorPopup from "./ErrorPopup";
import ByCountryM3U8 from "./ByCountryM3U8";
import "./AddM3U8list.css";

const AddM3U8list = ({
  lang,
  setM3u8Lists,
  guidePosition,
  setGuidePosition,
  setCountries,
}) => {
  const [url, setUrl] = useState("");
  const [name, setName] = useState("");
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const language = lang.internet_channels.add_m3u8_list;
  const [selectVisibleCountry, setSelectVisibleCountry] = useState(false);

  useEffect(() => {
    Services.getInternetChannelsFilters().then((res) => {
      setCountries(res.data.countries);
    });
  }, [setCountries]);

  const handleSubmit = () => {
    let params = { path: url, name };

    Services.m3u8upload(params)
      .then((res) => {
        if (res.data.error) {
          setError(true);
        } else {
          setSuccess(true);
          setM3u8Lists(res.data.m3u_lists);
        }
      })
      .catch(() => setError(true));
  };

  const deactiveGuideTootltips = () => {
    setGuidePosition(0);
    localStorage.setItem("showed_internettv_info", true);
  };

  return (
    <div className="addM3U8list_wrapper min_height grey">
      <Track />
      {success ? <SuccessPopup closePopup={() => setSuccess(false)} /> : null}
      {error ? <ErrorPopup closePopup={() => setError(false)} /> : null}
      <div className="container">
        <h2 className="title">{language.label}</h2>
        <div className="row">
          <div className="input_field">
            <input
              type="text"
              onChange={(e) => setUrl(e.target.value)}
              placeholder={language.url_placeholder}
            />
            {guidePosition === 11 ? (
              <div className="tooltip nine">
                <div className="triangle"></div>
                <p>{lang.guide.internet_channels.add_m3u8_list.input_info}</p>
                <div className="buttons">
                  <button
                    className="skip"
                    onClick={() => deactiveGuideTootltips()}
                  >
                    {lang.guide.internet_channels.skip_btn}
                  </button>
                  <button onClick={() => setGuidePosition(12)}>
                    {lang.channels.now_on_tv.filter.next}
                  </button>
                </div>
              </div>
            ) : null}
            <input
              type="text"
              onChange={(e) => setName(e.target.value)}
              placeholder={language.name_placeholder}
            />
            {guidePosition === 12 ? (
              <div className="tooltip ten">
                <div className="triangle"></div>
                <p>
                  {
                    lang.guide.internet_channels.add_m3u8_list
                      .m3u_list_name_info
                  }
                </p>
                <div className="buttons">
                  <button
                    className="skip"
                    onClick={() => deactiveGuideTootltips()}
                  >
                    {lang.guide.internet_channels.skip_btn}
                  </button>
                  <button onClick={() => setGuidePosition(13)}>
                    {lang.channels.now_on_tv.filter.next}
                  </button>
                </div>
              </div>
            ) : null}
            <button onClick={handleSubmit}>{language.save_btn}</button>
            {guidePosition === 13 ? (
              <div className="tooltip eleven">
                <div className="triangle"></div>
                <p>
                  {lang.guide.internet_channels.add_m3u8_list.save_list_info}
                </p>
                <div className="buttons">
                  <button
                    className="skip"
                    onClick={() => deactiveGuideTootltips()}
                  >
                    {lang.guide.internet_channels.skip_btn}
                  </button>
                  <button onClick={() => setGuidePosition(14)}>
                    {lang.channels.now_on_tv.filter.next}
                  </button>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <div className="selectByCountry">
          {/* <div className="select">
            <div
              onClick={() => setSelectVisible(!selectVisible)}
              className="top"
            >
              {activeItem && activeItem.name}
              <div className="arrows">
                <IoMdArrowDropup onClick={() => {}} />
                <IoMdArrowDropdown onClick={() => {}} />
              </div>
            </div>
            {selectVisible ? (
              <div className="options">
                {items.length
                  ? items.map((item, index) => (
                      <p
                        key={index}
                        onClick={() => handleClick(item, index)}
                        className={item.id === activeItem.id ? "active" : ""}
                      >
                        {item.name}
                      </p>
                    ))
                  : null}
              </div>
            ) : null}
          </div> */}
          <h2>Pretražite M3U8 kanale po zemlji</h2>
          <ByCountryM3U8
            language={lang}
            visible={selectVisibleCountry}
            toggle={setSelectVisibleCountry}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
  guidePosition: state.guidePosition,
});

export default connect(mapStateToProps, {
  setM3u8Lists,
  setGuidePosition,
  setCountries,
})(AddM3U8list);
