import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FaSort } from "react-icons/fa";
import { setM3u8Lists } from "../../../redux/actions";
import { Services } from "../../../services/Services";
import Track from "../Track/Track";
import ConfirmPopup from "./ConfirmPopup";
import "./M3U8lists.css";

const M3U8lists = ({ list, lang, setM3u8Lists }) => {
  const [isRemoved, setRemoved] = useState(false);
  const [list_id, setList_id] = useState(false);
  const [sortByNumber, setSortByNumber] = useState(false);
  const [sortByName, setSortByName] = useState(false);
  const language = lang.internet_channels.m3u8_lists;

  useEffect(() => {
    Services.m3u8lists().then(res => setM3u8Lists(res.data.m3u_lists));
  }, [setM3u8Lists]);

  const handleRemove = (id) => {
    setList_id(id);
    setRemoved(true);
  };

  const loadList = () => {
    Services.m3u8lists().then(res => setM3u8Lists(res.data.m3u_lists));
  }

  var length = list.length;

  return (
    <div className="M3U8lists_wrapper min_height grey">
      <Track />
      {isRemoved ? (
        <ConfirmPopup list_id={list_id} close={() => setRemoved(false)} loadList={loadList}/>
      ) : null}
      <div className="table_wrap">
        <div className="table_header">
          <div>
            <FaSort onClick={() => setSortByNumber(!sortByNumber)} />
            {language.number_label}
          </div>
          <div>
            <FaSort onClick={() => setSortByName(!sortByName)} />
            {language.name_label}
          </div>
          <div>
            {language.manage_label}
          </div>
        </div>
        <div className="table_body">
          {sortByNumber
            ? list
            .map(el => {
              if (el.name === null) {
                el.name = el.path;
                return el;
              } else {
                return el;
              }
            })
            .sort((a, b) => {
              if (sortByName) {
                return a.name.localeCompare(b.name);
              } else {
                return b.name.localeCompare(a.name);
              }
            })
            .reverse()
            .map((item, index) => (
              <div className="table_row" key={index}>
                <div className="table_row_number">
                  {!sortByNumber ? index + 1 : length--}
                </div>
                <div className="table_row_text">{item.name}</div>
                <div className="table_row_button">
                  <button onClick={() => handleRemove(item.id)}>
                    {language.remove_btn}
                  </button>
                </div>
              </div>
            ))
        : list
            .map(el => {
              if (el.name === null) {
                el.name = el.path;
                return el;
              } else {
                return el;
              }
            })
            .sort((a, b) => {
              if (sortByName) {
                return a.name.localeCompare(b.name);
              } else {
                return b.name.localeCompare(a.name);
              }
            })
            .map((item, index) => (
              <div className="table_row" key={index}>
                <div className="table_row_number">
                  {!sortByNumber ? index + 1 : length--}
                </div>
                <div className="table_row_text">{item.name}</div>
                <div className="table_row_button">
                  <button onClick={() => handleRemove(item.id)}>
                    {language.remove_btn}
                  </button>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  list: state.m3u8Lists,
  lang: state.selectedLanguage
})

export default connect(mapStateToProps, { setM3u8Lists })(M3U8lists);
