import React, { useState, useEffect, useRef } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import ReactPlayer from "react-player";
import {
  setPlayerVisible,
  setPinCodeModal,
  setLeftSliderPin,
  setAds,
} from "../../../redux/actions";
import { streaming, config } from "./helper";
import AboutEpg from "./AboutEpg";
import Controls from "./Controls";
import Volume from "./Volume";
import Progress from "./Progress";
import Loader from "./Loader";
import LeftSlider from "../LeftSlider/LeftSlider";
import BottomSlider from "../BottomSlider/BottomSlider";
import "../VideoPlayer.css";
import { MdClose } from "react-icons/md";
import FloatingText from "../../../components/Unauthorized/FloatingText/FloatingText";
// import ad from "../../../images/ad.png";
import { Services } from "../../../services/Services";

let timeout;
let rewinded = false;

const Player = ({
  data,
  epgs,
  setPlayerVisible,
  pincode,
  setPinCodeModal,
  setLeftSliderPin,
}) => {
  const dispatch = useDispatch();

  const [isPlaying, setIsPlaying] = useState(true);
  const [volume, setVolume] = useState(1);
  const [loader, setLoader] = useState(true);
  const [fullScreen, setFullScreen] = useState(false);
  const [fadeout, setFadeout] = useState(false);
  const { channel, epg } = data;

  const player = useRef(null);
  const [secPlayer, setSecPlayer] = useState(null);
  const [secUrl, setSecUrl] = useState(null);

  const [progress, setProgress] = useState(0);
  const [seeking, setSeeking] = useState(false);

  const [nextEpisode, setNextEpisode] = useState(null);

  const [adIndex, setAdIndex] = useState(0);
  const ads = useSelector((state) => state.ads);
  const [random, setRandom] = useState(Math.random());

  useEffect(() => {
    setRandom(Math.random());
  }, [data]);

  useEffect(() => {
    let body = document.querySelector("body");
    body.style.overflow = "hidden";
    return () => {
      body.removeAttribute("style");
      setPinCodeModal(null);
    };
  }, [setPinCodeModal]);

  useEffect(() => {
    if (!localStorage.jwtToken) {
      Services.getAds().then((res) => {
        dispatch(setAds(res.data.images));
      });
    }
    // eslint-disable-next-line
  }, []);

  // console.log(adIndex);

  useEffect(() => {
    if (ads.length > 0) {
      const interval = setInterval(() => {
        const temp = adIndex + 1;
        setAdIndex(temp % ads.length);
      }, 8000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line
  }, [ads, adIndex]);

  useEffect(() => {
    if (pincode === "success")
      setTimeout(() => {
        setLeftSliderPin(null);
      }, 2000);
  }, [pincode, setLeftSliderPin]);

  const toggleFullScreen = () => {
    if (fullScreen) {
      document.exitFullscreen();
      setFullScreen(false);
    } else {
      document.querySelector(".video_player_wrapper").requestFullscreen();
      setFullScreen(true);
    }
  };

  const classes = () =>
    fadeout ? "video_player_wrapper fadeout" : "video_player_wrapper";

  const onReady = () => {
    setIsPlaying(true);
    setLoader(false);
    if (!rewinded && data.rewind) {
      rewinded = true;
      rewindHandler();
    }
  };

  const onMouseMove = (e) => {
    let div = document
      .querySelector(".video_player_mouse_move")
      .getBoundingClientRect();
    if (div.left < e.pageX && e.pageY < div.bottom) {
      setFadeout(false);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setFadeout(true);
      }, 3000);
    } else {
      setFadeout(false);
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        setFadeout(false);
      }, 3000);
    }
  };

  const progressHandler = (state) => {
    if (!seeking) {
      setProgress(state.played);
    }
  };

  const disableSeeking = () => {
    setSeeking(false);
  };

  const enableSeeking = () => {
    setSeeking(true);
  };

  const enablePip = () => {
    setSecUrl(player.current.props.url);
    if (!secPlayer) {
      setSecPlayer(player.current.props);
    }
  };

  const disablePip = () => {
    setSecPlayer(null);
    setSecUrl(null);
  };

  const playNext = () => {
    epgs.forEach((check, index) => {
      if (check.id === epg.id) {
        if (epgs[index + 1].src) {
          return setNextEpisode(epgs[index + 1]);
        }
      }
    });
    return null;
    // return player.current.seekTo(player.current.getDuration() )
  };

  const playCurrent = () => {
    return setNextEpisode(channel.current_epg);
  };

  const rewindHandler = () => {
    player.current.seekTo(0);
  };

  const onStart = () => {
    if (data.fromStart) {
      rewindHandler();
    }
  };

  return (
    <div
      className={classes() + (!localStorage.jwtToken ? " demo" : "")}
      onClick={() => setIsPlaying(!isPlaying)}
      onDoubleClick={toggleFullScreen}
      onMouseMove={onMouseMove}
    >
      {!localStorage.jwtToken &&
        (ads.length > 0 ? (
          <img src={ads[adIndex].url} className="ad" alt="Ad" />
        ) : (
          <></>
        ))}
      {!localStorage.jwtToken && <FloatingText />}
      <ReactPlayer
        config={config(channel)}
        url={streaming(channel, epgs, epg)}
        onProgress={progressHandler}
        onReady={onReady}
        onStart={onStart}
        volume={volume}
        ref={player}
        playing={isPlaying}
        onEnded={playNext}
        key={random}
      />

      <ReactPlayer
        style={{ display: "none" }}
        config={config(channel)}
        url={secUrl ? secUrl : streaming(channel, epgs, epg)}
        onReady={onReady}
        onStart={onStart}
        volume={0}
        playing={true}
        pip={secPlayer ? true : false}
        onDisablePIP={disablePip}
      />

      <div className="close-player" onClick={() => setPlayerVisible(null)}>
        <MdClose size={50} />
      </div>
      <div className="video_player_mouse_move" />
      <div
        className="video_player_bottom_side"
        onClick={(e) => e.stopPropagation()}
        onDoubleClick={(e) => e.stopPropagation()}
      >
        <div className="top_part">
          <AboutEpg channel={channel} />
          <Controls
            player={player}
            channel={channel}
            epg={epg}
            isPlaying={isPlaying}
            setIsPlaying={setIsPlaying}
            forward={playCurrent}
            rewind={rewindHandler}
          />
          {
            <Volume
              volume={volume}
              setVolume={setVolume}
              toggleFullScreen={toggleFullScreen}
              epg={epg}
              epgs={epgs}
              channel={channel}
              player={player}
              enablePip={enablePip}
              internetTv={data.channel.internet_channel === 0 ? false : true}
              fta={data.channel.is_fta}
              local={data.channel.is_local}
              m3u={data.channel.is_m3u}
            />
          }
        </div>
        {channel.tutorial || (player.current && epg) ? (
          <Progress
            player={player}
            played={progress}
            disableSeeking={disableSeeking}
            enableSeeking={enableSeeking}
          />
        ) : null}
        {!channel.tutorial && epgs && epgs.length && (
          <BottomSlider data={data} nextEpisode={nextEpisode} />
        )}
      </div>
      {<LeftSlider fullScreen={fullScreen} data={data} />}
      {loader && <Loader />}
    </div>
  );
};

const mapStateToProps = (state) => ({
  data: state.playerVisible,
  pincode: state.leftSliderPin,
  epgs: state.epgs,
});

export default connect(mapStateToProps, {
  setPlayerVisible,
  setPinCodeModal,
  setLeftSliderPin,
})(Player);
