import React, { useState, useEffect } from 'react';
import SettingsMenu from '../SettingsMenu/SettingsMenu';
import { FaCheck } from 'react-icons/fa';
import { connect } from 'react-redux';
import { Services } from '../../../services/Services';

const Languages = ({ lang }) => {
  const [allLang, setAllLang] = useState([]);

  useEffect(() => {
    Services.allLanguages().then(res => setAllLang(res.data.languages));
  }, []);

  const handleClick = id => {
    localStorage.removeItem('locale');
    Services.changeLanguage({ language_id: id }).then(() => window.location.href = '/settings/languages');
  };

  return (
    <div className='min_height flex white'>
      <SettingsMenu />
      <div className='settings_content languages'>
        <h3>{lang.settings.submenu.language}</h3>
        {allLang.map(item => (
          <div
            key={item.id}
            className='row_between cursor'
            onClick={() => handleClick(item.id)}
          >
            <p>{item.name}</p>
            {lang.name === item.name ? (
              <span className='active_check'>
                <FaCheck />
              </span>
            ) : (
              <span className='active_check transparent'/>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  lang: state.selectedLanguage
});

export default connect(mapStateToProps)(Languages);
