import React, { useEffect } from "react";
import { BrowserRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Services } from "./services/Services";
import {
  setSelectedLanguage,
  setLockedCategories,
  setPinCode,
  setChannels,
  setLoader,
} from "./redux/actions";
import Routes from "./Routes";
import Spinner from "./Spinner";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import Player from "./components/VideoPlayer/Player/Player";
import InfoModal from "./components/InfoModal/InfoModal";
import PINcode from "./components/PINcode/PINcode";
import LeftSliderPIN from "./components/VideoPlayer/LeftSlider/LeftSliderPIN";

const Root = ({
  info_modal,
  pincode,
  left_slide_pin,
  loader,
  player,
  lang,
  setSelectedLanguage,
  setLockedCategories,
  setPinCode,
  setChannels,
  setLoader,
  locked,
}) => {
  useEffect(() => {
    let param = { filter: "", category: "", sort: "", favorite: "", epgs: 1 };

    Services.getUser()
      .then((res) => {
        setLockedCategories(res.data.locked_categories);
        setPinCode(res.data.pincode);
      })
      .catch((err) => {
        localStorage.clear();
        window.location.href = "/";
      });

    Services.changeLanguage({ language_id: localStorage.locale })
      .then(() =>
        Services.getLanguage().then((res) =>
          setSelectedLanguage(res.data.language)
        )
      )
      .catch((err) => {
        localStorage.clear();
        window.location.href = "/";
      });

    Services.getChannels(param)
      .then((res) => {
        setChannels(res.data);
        setLoader(false);
      })
      .catch((err) => {
        localStorage.clear();
        window.location.href = "/";
      });
    // eslint-disable-next-line
  }, [
    setLockedCategories,
    setPinCode,
    setSelectedLanguage,
    setChannels,
    setLoader,
    locked,
  ]);

  useEffect(() => {
    var currentYear = new Date().getFullYear();
    var currentMonth = new Date().getMonth() + 1;
    var currentDay = new Date().getDate();
    var expireDate = localStorage.getItem("expire_date").split("/");

    if (parseInt(expireDate[2]) < currentYear) {
      localStorage.clear();
      // window.location.href = "/"
      console.log(expireDate[2], currentYear);
    } else if (
      parseInt(expireDate[2]) === currentYear &&
      parseInt(expireDate[1]) < currentMonth
    ) {
      localStorage.clear();
      // window.location.href = "/"
      console.log(expireDate[1], currentMonth);
    } else if (
      parseInt(expireDate[2]) === currentYear &&
      parseInt(expireDate[1]) === currentMonth &&
      parseInt(expireDate[0] < currentDay)
    ) {
      localStorage.clear();
      // window.location.href = "/"
      console.log(expireDate[0], currentDay);
    }
  }, []);
  return (
    <>
      {loader && <Spinner lang={lang} />}
      {player && <Player />}
      {info_modal && <InfoModal />}
      {pincode === "opened" && <PINcode />}
      {left_slide_pin === "opened" && <LeftSliderPIN />}
      {lang.menu && (
        <BrowserRouter>
          <Navbar />
          <div className="content">
            <Routes />
          </div>
          <Footer />
        </BrowserRouter>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  loader: state.loader,
  lang: state.selectedLanguage,
  player: state.playerVisible,
  info_modal: state.info_modal,
  pincode: state.pinCodeModal,
  left_slide_pin: state.leftSliderPin,
});

export default connect(mapStateToProps, {
  setSelectedLanguage,
  setLockedCategories,
  setPinCode,
  setChannels,
  setLoader,
})(Root);
