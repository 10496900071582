import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { FaAngleDown } from "react-icons/fa";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import { Services } from "../../../services/Services";
import { makeGrid } from "../../../helpers/makeGrid";
import { setLoader } from "../../../redux/actions";
import Track from "../Track/Track";
import Grid from "../../../components/Grid/Grid";
import "./NowOnTV.css";
import PaginationLoader from "../../../components/PaginationLoader/PaginationLoader";
import Loader from "../../../components/VideoPlayer/Player/Loader";

const NowOnTV = ({ lang, loader, setLoader, param }) => {
  const [data, setData] = useState([]);
  const [periodVisible, setPeriodVisible] = useState(false);
  const [period, setPeriod] = useState("now");
  const [periodText, setPeriodText] = useState("");
  const [sortVisible, setSortVisible] = useState(false);
  const [sort, setSort] = useState("date");
  const [sortText, setSortText] = useState("");
  const [paramReq, setParamReq] = useState({
    page: 1,
    per_page: 50,
  });
  const [lastPage, setLastPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [isPagination, setIsPagination] = useState(true);
  const [periodChangeLoader, setPeriodChangeLoader] = useState(false);

  const language = lang.channels.now_on_tv.filter;
  const elements = data || [];
  const components = elements.map((item, index) => (
    <Grid key={index} item={item} />
  ));

  const seeMore = () => {
    if (paramReq.page + 1 <= lastPage) {
      setParamReq((prevState) => ({
        ...prevState,
        page: prevState.page + 1,
      }));
      setIsPagination(true);
    }
  };

  const fetchAPI = () => {
    setLoading(true);
    !isPagination && setPeriodChangeLoader(true);
    Services.onTVPagination(
      period,
      sort,
      param.category,
      paramReq.per_page,
      paramReq.page
    ).then((res) => {
      let arr = [];
      res.data.data.map((item) =>
        arr.push({ channel: item.channel, epg: item })
      );
      isPagination ? setData(data.concat(arr)) : setData(arr);
      setLastPage(res.data.meta.last_page);
      setPeriodChangeLoader(false);
      setLoading(false);
    });
  };

  useEffect(() => {
    setPeriodText(language.now);
    setSortText(lang.sort.placeholder);
  }, [language, lang]);

  useEffect(() => {
    fetchAPI();
    // eslint-disable-next-line
  }, [period, sort, setLoader, param, paramReq]);

  const closeDropdowns = () => {
    if (periodVisible) setPeriodVisible(false);
    if (sortVisible) setSortVisible(false);
  };

  const handleClick = (e, val, who) => {
    let text = e.target.textContent;
    if (who === 1) {
      setPeriodText(text);
      setPeriod(val);
    } else {
      setSortText(text);
      setSort(val);
    }
    setParamReq({ per_page: 50, page: 1 });
    setIsPagination(false);
  };

  return (
    <div className="min_height white now_on_tv_screen" onClick={closeDropdowns}>
      <Track />
      {periodChangeLoader && <Loader />}
      <div className="container">
        <div className="row">
          <h2 className="title">{periodText}</h2>
          <div>
            <div
              className="dropdown"
              onClick={() => setPeriodVisible(!periodVisible)}
            >
              <p>{periodText}</p>
              <FaAngleDown className={periodVisible && "rotate"} />
              {periodVisible && (
                <div className="dropdown_options">
                  <p onClick={(e) => handleClick(e, "prev", 1)}>
                    {language.previous}
                  </p>
                  <p onClick={(e) => handleClick(e, "now", 1)}>
                    {language.now}
                  </p>
                  <p onClick={(e) => handleClick(e, "next", 1)}>
                    {language.next}
                  </p>
                </div>
              )}
            </div>
            <div
              className="dropdown"
              onClick={() => setSortVisible(!sortVisible)}
            >
              <p>{sortText}</p>
              <FaAngleDown className={sortVisible && "rotate"} />
              {sortVisible && (
                <div className="dropdown_options">
                  <p onClick={(e) => handleClick(e, "asc", 2)}>
                    {lang.sort.asc.name}
                  </p>
                  <p onClick={(e) => handleClick(e, "desc", 2)}>
                    {lang.sort.desc.name}
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        {data.length > 0 ? (
          <>
            <div className="grid_items_wrapper">{makeGrid(components)}</div>
            {loading && (
              <div style={{ position: "relative", height: 70 }}>
                <PaginationLoader />
              </div>
            )}
            {!loading && paramReq.page < lastPage && (
              <div className="see_more_wrapper">
                <button className="see_more_btn" onClick={seeMore}>
                  {lang.movies.see_more_btn}
                </button>
              </div>
            )}
          </>
        ) : (
          <div className="wait">{!loader && <AiOutlineLoading3Quarters />}</div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  lang: state.selectedLanguage,
  loader: state.loader,
  param: state.channelsParams,
});

export default connect(mapStateToProps, { setLoader })(NowOnTV);
